<script setup lang="ts">
    import router from '@/router';
    import { Navigation } from '@/router/navigation';
    import Button from 'primevue/button';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();
</script>

<template>
    <div class="signup-success-container">
        <h2 class="heading">{{ t('LOGIN.SIGNUP_SUCCESS.TITLE') }}</h2>
        <p>{{ t('LOGIN.SIGNUP_SUCCESS.DESCRIPTION') }}</p>
        <Button
            :label="t('LOGIN.SIGNUP_SUCCESS.CTA_BUTTON')"
            @click="router.push({ name: Navigation.SalesProductList })"
        />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main' as *;

    .heading {
        font-weight: 700;
        margin-bottom: $spacing-6;
    }

    .signup-success-container {
        margin: 0 auto;
        background: $vt-c-white;
        padding: $spacing-6;
        text-align: center;
        max-width: 588px;
    }
</style>
